.modal-form__image-wrapper {
  width: ($base-spacing * 17.5);
  height: $base-spacing * 9;
  margin: $base-spacing * 2 auto;
  display: block;
  overflow: hidden;
  direction: rtl;
}

.modal-form__image {
  height: $base-spacing * 9;
  width: auto;
  max-width: none;
}

.modal-form__title,
.modal-form__subtitle {
  text-align: center;
  margin-bottom: $base-spacing * 1.5;
}

.modal-form__title {
  font-family: $font-decorative;
  @include typography(h500);
}

.modal-form__subtitle {
  @include typography(h700);
  font-weight: bold;
}

.modal-form__description {
  margin-bottom: $base-spacing * 2;
  text-align: center;
}

.modal-form__alert {
  margin-bottom: $base-spacing;
}

.modal-form__reveal {
  margin-top: $base-spacing * 2;
}

@include breakpoint(screen768) {
  .modal-form__title {
    margin-bottom: $base-spacing * 2;
  }
}
