.notice .alert {
  margin-bottom: 0.5rem;
  white-space: nowrap;
}

@include breakpoint(screen768) {
  .notice {
    display: flex;
    flex-direction: row;
    align-items: center;

    .alert {
      margin-bottom: 0;
    }
  }
}
