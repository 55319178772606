@import "~acca-design-system/src/styles/app.scss";
@import "~acca-design-system/dist/es/acca-design-system.css";

@import "modal-form";
@import "section";
@import "notice";
@import "utility-classes";

html {
  height: 100%;
}

body {
  min-height: 100%;
}

body {
  background: get-color(grey-10);
  overflow-y: scroll;
  overflow-x: hidden;
}

dl {
  dt:last-of-type,
  dd:last-of-type {
    padding-bottom: 0;
  }
}

// move to DS
.vertical-nav__back-btn {
  cursor: pointer;
}

.form-label__asterisk {
  font-size: 1.5em;
  color: get-color(error);
  vertical-align: middle;
}
