@import "~acca-design-system/src/styles/core.scss";

.section {
  margin-bottom: $base-spacing * 4;
}

.section-title {
  @include typography(h600);
  margin-bottom: $base-spacing;
  font-weight: bold;
}

.section--top {
  margin-top: $base-spacing * 4;
}
